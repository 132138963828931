
import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import { CheckUnsavedDataMixin } from '@/mixins/CheckUnsavedData';
import CmsMenu from '../components/views/settings/CmsMenu.vue';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import { RouteNames } from '@/enums/routes/RouteNames';
import i18n from '@/i18n';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import IntroScreenRepository from '@/repositories/IntroScreenRepository';
import IntroScreen from '@/models/IntroScreen';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import { NavigationGuardNext, Route } from 'vue-router';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';

@Component({
    name: 'CmsContent',
    components: {
        CmsMenu,
        SettingsTopBar,
    },
    mixins: [CheckUnsavedDataMixin],
})
export default class CmsContent extends mixins<UserRightsMixin>(UserRightsMixin) {
    private backRoute: RouteNames = RouteNames.settings;
    private menuData: Array<{
        icon: any;
        text: string;
        canSee?: any;
        redirect: any;
    }> = [];

    private created() {
        this.createMenu(this.$route);
    }
    private async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        if (to.name === RouteNames.contentSubcategory) {
            this.backRoute = RouteNames.cmsContent;
            this.createMenu(to);
            next();
        } else if (to.name === RouteNames.cmsContent) {
            this.backRoute = RouteNames.settings;
            this.createMenu(to);
            next();
        } else {
            next();
        }
    }

    private createMenu(route: Route) {
        if (route.params.subCategory === undefined) {
            this.menuData = [
                {
                    icon: 'user',
                    text: i18n.t('Client Content'),
                    canSee: this.canViewUsersTab,
                    redirect: this.goToClientContent,
                },
                {
                    icon: 'interaction',
                    text: i18n.t('Inquiry content'),
                    canSee: true,
                    redirect: this.goToInquiryContent,
                },
                {
                    icon: 'hdd',
                    text: i18n.t('Project content'),
                    canSee: true,
                    redirect: this.goToProjectContent,
                },
                {
                    icon: 'cluster',
                    text: i18n.t('B2B settings content'),
                    canSee: true,
                    redirect: this.goToB2BContent,
                },
                {
                    icon: 'folder',
                    text: i18n.t('Other content'),
                    canSee: true,
                    redirect: this.goToOtherContent,
                },
            ];
        } else if (route.params.subCategory === 'client-content') {
            this.menuData = [
                {
                    icon: 'snippets',
                    text: i18n.t('Tipovi klijenata'),
                    canSee: this.canSeeClientTypesItem,
                    redirect: this.goToClientTypes,
                },
                {
                    icon: 'euro',
                    text: i18n.t('Vrsta plaćanja'),
                    canSee: true,
                    redirect: this.goToPaymentTypes,
                },
            ];
        } else if (route.params.subCategory === 'inquiry-content') {
            this.menuData = [
                {
                    icon: 'calendar',
                    text: i18n.t('Kampanje'),
                    canSee: this.canSeeCampaignsItem,
                    redirect: this.goToCampaigns,
                },
                {
                    icon: 'sliders',
                    text: i18n.t('Tipovi kampanja'),
                    canSee: this.canSeeCampaignTypesItem,
                    redirect: this.goToCampaignTypes,
                },
                {
                    icon: 'file-ppt',
                    text: i18n.t('Vrsta upita'),
                    canSee: this.canSeeLeadTypesItem,
                    redirect: this.goToLeadTypes,
                },
                {
                    icon: 'monitor',
                    text: i18n.t('Izvor upita'),
                    canSee: this.canSeeLeadOriginsItem,
                    redirect: this.goToLeadOrigins,
                },
            ];
        } else if (route.params.subCategory === 'project-content') {
            this.menuData = [
                {
                    icon: 'form',
                    text: i18n.t('Order for PDF templates'),
                    canSee: this.canSeeOrderPdfTemplatesItem,
                    redirect: this.goToOrderPdfTemplatesIndex,
                },
                {
                    icon: 'table',
                    text: i18n.t('Polja formi'),
                    canSee: this.canSeeInfoButtonsItem,
                    redirect: this.goToCheckListIndex,
                },
                {
                    icon: 'key',
                    text: i18n.t('Oznake'),
                    canSee: this.canSeeStatusesItem,
                    redirect: this.goToLabels,
                },
                {
                    icon: 'fork',
                    text: i18n.t('Tijek rada'),
                    canSee: true,
                    redirect: this.goToWorkflow,
                },
                {
                    icon: 'profile',
                    text: i18n.t('Porezi'),
                    canSee: this.canSeeTaxesItem,
                    redirect: this.goToTaxes,
                },
                {
                    icon: 'percentage',
                    text: i18n.t('Detalji poreza na PDF-u'),
                    canSee: this.canSeeTaxesItem,
                    redirect: this.goToTaxDetails,
                },
                {
                    icon: 'mail',
                    text: i18n.t('Vrste dokumenata'),
                    canSee: this.canSeeDocumentNamesItem,
                    redirect: this.goToDocumentNames,
                },
                {
                    icon: 'file-pdf',
                    text: i18n.t('Opcije PDF-a'),
                    canSee: this.canSeeOfferConfigurationsItem,
                    redirect: this.goToPdfOfferOptions,
                },
                {
                    icon: 'rise',
                    text: i18n.t('Status narudžbe'),
                    canSee: this.canSeeOrderStatusItem,
                    redirect: this.goToOrderStatuses,
                },
                {
                    icon: 'credit-card',
                    text: i18n.t('Automatske promjene cijena'),
                    canSee: this.canSeeAutomaticPriceManipulations,
                    redirect: this.goToAutomaticPriceManipulations,
                },
                {
                    icon: 'message',
                    text: i18n.t('Email predlošci'),
                    canSee: this.canSeeEmailTemplateProperties,
                    redirect: this.goToEmailTemplateProperties,
                },
                {
                    icon: 'flag',
                    text: i18n.t('Flagged PDF values'),
                    canSee: true,
                    redirect: this.goToFlaggedPdfValues,
                },
                {
                    icon: 'alert',
                    text: i18n.t('Automatic events for statuses'),
                    canSee: true,
                    redirect: this.goToAutomaticEvents,
                },
                {
                    icon: 'warning',
                    text: i18n.t('Valid warranty warnings'),
                    canSee: true,
                    redirect: this.goToWarrantyWarnings,
                },
            ];
        } else if (route.params.subCategory === 'b2b-content') {
            this.menuData = [
                {
                    icon: 'control',
                    text: i18n.t('Predlošci oznaka'),
                    canSee: this.isCurrentUserGroupRoltek,
                    redirect: this.goToProjectLabelTemplates,
                },
                {
                    icon: 'gateway',
                    text: i18n.t('Predlošci tijekova rada'),
                    canSee: this.isCurrentUserGroupRoltek,
                    redirect: this.goToWorkflowTemplate,
                },
            ];
        } else if (route.params.subCategory === 'other-content') {
            this.menuData = [
                {
                    icon: 'double-right',
                    text: i18n.t('Tipovi sljedećih koraka'),
                    canSee: this.canSeeNextStepTypesItem,
                    redirect: this.goToNextStepTypes,
                },
                {
                    icon: 'double-right',
                    text: i18n.t('Tipovi sljedećih koraka'),
                    canSee: this.canSeeNextStepTypesItem,
                    redirect: this.goToNextStepTypes,
                },
                {
                    icon: 'environment',
                    text: i18n.t('Poštanski brojevi'),
                    canSee: this.canSeePostCodeConnectionsItem,
                    redirect: this.goToPostCodeConnections,
                },
                {
                    icon: 'dollar',
                    text: i18n.t('Currencies'),
                    canSee: this.canSeeCurrenciesItem,
                    redirect: this.goToCurrencies,
                },
                {
                    icon: 'usergroup-add',
                    text: i18n.t('Korisničke grupe'),
                    canSee: this.canSeeUserGroupsItem,
                    redirect: this.goToUserGroups,
                },
                {
                    icon: 'appstore',
                    text: i18n.t('Početni zaslon'),
                    canSee: true,
                    redirect: this.goToIntroScreenCms,
                },
                {
                    icon: 'bank',
                    text: i18n.t('Bankovni računi'),
                    canSee: true,
                    redirect: this.goToBankAccounts,
                },
                {
                    icon: 'windows',
                    text: i18n.t('Resources'),
                    canSee: true,
                    redirect: this.goToResources,
                },
                {
                    icon: 'calendar',
                    text: i18n.t('Schedules'),
                    canSee: true,
                    redirect: this.goToSchedules,
                },
                {
                    icon: 'fund',
                    text: i18n.t('Grupe proizvoda'),
                    canSee: true,
                    redirect: this.goToProductGroups,
                },
                {
                    icon: 'arrows-alt',
                    text: i18n.t('Dimensions wizard'),
                    canSee: true,
                    redirect: this.goToDimensionsWizard,
                },
                {
                    icon: 'layout',
                    text: i18n.t('Dashboards'),
                    canSee: true,
                    redirect: this.goToDashboards,
                },
                {
                    icon: 'pie-chart',
                    text: i18n.t('Statistics'),
                    canSee: true,
                    redirect: this.goToStatistics,
                },
                {
                    icon: 'mail',
                    text: i18n.t('News'),
                    canSee: true,
                    redirect: this.goToNews,
                },
            ];
        }
    }

    private goToClientContent() {
        this.$router.push({
            name: RouteNames.contentSubcategory,
            params: {
                subCategory: 'client-content',
            },
        });
    }
    private goToInquiryContent() {
        this.$router.push({
            name: RouteNames.contentSubcategory,
            params: {
                subCategory: 'inquiry-content',
            },
        });
    }
    private goToProjectContent() {
        this.$router.push({
            name: RouteNames.contentSubcategory,
            params: {
                subCategory: 'project-content',
            },
        });
    }
    private goToB2BContent() {
        this.$router.push({
            name: RouteNames.contentSubcategory,
            params: {
                subCategory: 'b2b-content',
            },
        });
    }

    private goToOtherContent() {
        this.$router.push({
            name: RouteNames.contentSubcategory,
            params: {
                subCategory: 'other-content',
            },
        });
    }

    private goToCheckListIndex() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.ChecklistFields,
            },
        });
    }

    private goToOrderPdfTemplatesIndex() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.PdfOrderTemplatesOptions,
            },
        });
    }

    private goToLabels() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.Labels,
            },
        });
    }

    private goToProjectLabelTemplates() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.ProjectLabelTemplates,
            },
        });
    }

    private goToWorkflow() {
        this.$router.push({
            name: RouteNames.workflow,
        });
    }

    private goToWorkflowTemplate() {
        this.$router.push({
            name: RouteNames.workflowTemplate,
        });
    }

    private goToCampaignTypes() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.CampaignTypes,
            },
        });
    }

    private goToCampaigns() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.Campaigns,
            },
        });
    }

    private goToNextStepTypes() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.NextStepTypes,
            },
        });
    }

    private goToClientTypes() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.ClientTypes,
            },
        });
    }

    private goToTaxes() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.Taxes,
            },
        });
    }

    private goToTaxDetails() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.TaxDetails,
            },
        });
    }

    private goToDocumentNames() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.DocumentNames,
            },
        });
    }

    private goToPdfOfferOptions() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.PdfOfferOptions,
            },
        });
    }

    private goToUserGroups() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.UserGroups,
            },
        });
    }

    private goToLeadTypes() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.LeadTypes,
            },
        });
    }

    private goToBankAccounts() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.BankAccount,
            },
        });
    }

    private goToLeadOrigins() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.LeadOrigins,
            },
        });
    }

    private goToCurrencies() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.Currency,
            },
        });
    }

    private goToOrderStatuses() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.OrderStatus,
            },
        });
    }

    private goToPaymentTypes() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.PaymentTypes,
            },
        });
    }

    private goToAutomaticPriceManipulations() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.AutomaticPriceManipulations,
            },
        });
    }

    private goToPostCodeConnections() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.PostCodeConnections,
            },
        });
    }

    private goToEmailTemplateProperties() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.EmailTemplateProperties,
            },
        });
    }

    private goToProductGroups() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.ProductGroups,
            },
        });
    }

    private async goToDashboards() {
        this.$router.push({
            name: RouteNames.dashboards,
        });
    }
    private goToFlaggedPdfValues() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.FlaggedPdfValues,
            },
        });
    }

    private async goToStatistics() {
        this.$router.push({
            name: RouteNames.cmsStatistics,
        });
    }
    private goToResources() {
        this.$router.push({
            name: RouteNames.schedulesResources,
            params: {
                dynamic: 'CalendarResources',
            },
        });
    }
    private goToSchedules() {
        this.$router.push({
            name: RouteNames.schedulesResources,
            params: {
                dynamic: 'CalendarSchedules',
            },
        });
    }
    private goToDimensionsWizard() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.DimensionsWizard,
            },
        });
    }
    private async goToIntroScreenCms() {
        await IntroScreen.getAll();
        const introScreen = IntroScreenRepository.getFirst();
        this.$router.push({
            name: RouteNames.cmsIndex,
            params: {
                entityName: CmsEntityTypes.IntroScreenItem,
            },
        });
    }
    private async goToNews() {
        this.$router.push({
            name: RouteNames.cmsNews,
        });
    }

    private goToProductDetails() {
        EventBus.$emit(EventBusEvents.deselectSelectedProductSystem);
        this.$router.push({
            name: RouteNames.cmsIndex,
            params: {
                entityName: CmsEntityTypes.ProductDetails,
                entityId: 'start',
            },
        });
    }
    private goToProductImage() {
        EventBus.$emit(EventBusEvents.deselectSelectedProductSystem);
        this.$router.push({
            name: RouteNames.cmsIndex,
            params: {
                entityName: CmsEntityTypes.ProductImage,
                entityId: 'start',
            },
        });
    }

    private goToAutomaticEvents() {
        this.$router.push({
            name: RouteNames.automaticEvents,
        });
    }

    private goToWarrantyWarnings() {
        this.$router.push({
            name: RouteNames.cmsShow,
            params: {
                entityName: CmsEntityTypes.WarrantyWarnings,
            },
        });
    }
}
